<template>
  <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
  >
      <template slot="operation" slot-scope="text, record">
          <i class="z-icon delete" @click="deleteItem(record.id)"></i>
      </template>
  </a-table>
</template>
<script>
const columns = [
  {
    title: '科目名称',
    dataIndex: 'name',
    key:1,
    align:'center'
  }, {
    title: '题库',
    dataIndex: 'problems_count',
    key:2,
    align:'center'
  }, {
    title: '课程',
    dataIndex: 'course_count',
    key:3,
    align:'center'
  },{
    title: '操作',
    dataIndex: 'operation',
    key:4,
    scopedSlots: { customRender: 'operation' },
  },
]; 
import * as http from '@/libs/examapi'
export default {
    name: 'subject',
    data(){
        return{
          columns,
          data:[],
          loading:false
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      getData(){
        this.loading = true
        http.get_class_detail({id:this.$route.query.id}).then(res=>{
          this.data = res
          this.loading = false
        }).catch(error=>{
          console.log(error)
          this.loading = false
        })
      }
    }
}
</script>