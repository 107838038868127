<template>
    <div class="detail pd20x flex1">
        <AddClasses :visible="openClassBag" @close="openClassBag = false" :options="options" :data="result" :edit="true" :allSubject="allSubject" @update="getDetail"></AddClasses>
        <div class="detailInfo">
            <div class="imgWrap">
                <img :src="result.img" alt="">
            </div>
            <div>
                <div class="name">{{result.name}}</div>
                <p>所属分类：</p>
                <p><span class="mr20px">简称：{{result.short_name}}</span><span class="mr20px">标题：{{result.title}}</span><span >标签：{{result.tag}}</span></p>
                <p><span style="font-size:16px;font-weight:600;color:#ff5f4e;margin-right:20px">￥{{result.now_price}}</span><span style="color:#888;text-decoration:line-through">{{result.old_price}}</span></p>
            </div>
            <div class="rBtn"><a-button type="primary" @click="openClassBag = true">开通课程</a-button></div>
        </div>
        <div style="margin-top:5px;padding-top:10px" class="contentBox">
            <div class="radio-line">
                <div class="radio-line-item" v-for="(item, index) in tabList" :key="index" :class="{active: index == activeIndex}" @click="activeIndex = index">{{item}}</div>
            </div>
            <template v-if="activeIndex == 0">
                <subjects></subjects>
            </template>
            <template v-if="activeIndex == 1">
                <!-- <orderRecord></orderRecord> -->
            </template>
            <template v-if="activeIndex == 2">
            </template>
        </div>
    </div>
</template>
<script>
import subjects from './subjects'
import AddClasses from '@/components/exam/addClasses'
// import orderRecord from './orderRecord'
// import dealRecord from './dealRecord'
import * as http from '@/libs/examapi'
export default {
    name: 'classesDetail',
    components: { subjects, AddClasses },
    mounted(){
        this.id = this.$route.query.id 
        this.$nextTick(()=>{
            Promise.all([this.getAllSubject(),this.getCates()]).then(res=>{
                this.getDetail()
            })
        })
    },
    data(){
        this.tabList = ['所含科目','学习人数 ', '详情页']
        return{
            activeIndex: 0,
            openClassBag: false,
            id: 0,
            result:{},
            options:[],
            allSubject:[]
        }
    },
    methods:{
         getCates(){
            return new Promise((resolve, reject)=>{
                http.get_subject_cate().then(res=>{
                    this.options = res
                    resolve()
                }).catch(error=>{
                    reject()
                })
            }) 
        },
        getAllSubject(){
            return new Promise((resolve, reject)=>{
                http.get_allSubject().then(res=>{
                    this.allSubject = res
                     resolve()
                }).catch(error=>{
                    reject()
                })
            })
        },
        getDetail(){
            http.get_subject_detail({id: this.id}).then(res=>{
                this.result = res
            }).catch(error=>{
                console.log(error)
            })
        }
    }
}
</script>
<style lang="less" scoped>
.detail{
    display: flex;
    flex-direction: column;
   .imgWrap{
       width: 220px;
       height: 150px;
       border-radius: 4px;
       overflow: hidden;
   }
}
.contentBox{
    flex: 1;
}
</style>